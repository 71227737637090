import React from 'react';
import Page from '../../containers/Page';

import { FormHeader } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Block } from '../../components/Section';

import { initStateContent } from '../ContentPage/content';

class ContentPage extends React.Component {
  state = { ...initStateContent(this.props) };

  render() {
    const defaultPage = 'contact';
    const page = this.props.match.params.page;
    const content = this.state[page] || this.state[defaultPage];
    return (
      <Page id="form-page">
        <FormHeader {...this.props} page={page} content={content} />
        <Block
          page={page}
          classes={
            'section--background--skewed--merged-with-header section--background--skewed--no-icon'
          }
          {...content}
        >
          {content.content}
        </Block>
        {content.customBlocks &&
          content.customBlocks.map((Block, i) => {
            return Block(i);
          })}
        <Footer />
      </Page>
    );
  }
}

export default ContentPage;
